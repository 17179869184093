<template>
  <card>
    <template #header>
      <div class="header-top">
        <span class="box-card-icon icon-1" />
        <span class="box-card-title">Vesting: &nbsp;NLC</span>
      </div>
    </template>
    <template #body>
      <div class="box-card-body-top ml-3">
        <div class="card-input-group">
          <label class="label-text" for="balance">
            Balance: {{ (vestingPool.account.balance > 0 ? toFormattedToken(vestingPool.account.balance) : '0') + ' ' + account.symbol }}
          </label>
          <label class="label-text" for="earn-claimable">
            Earn Claimable:
            {{
              (vestingPool.account.claimable > 0 ? toFormattedToken(vestingPool.account.claimable) : '0') +
                ' ' +
                account.symbol
            }}
          </label>
          <div class="withdraw box-card-input mr-3">
            <span class="card-input-icon max-icon" />
            <input
              :value="vestingPool.account.claimable > 0 ? toFormattedToken(vestingPool.account.claimable) : 0"
              type="text"
              class="card-input card-input-number"
              placeholder="0"
              :disabled="true"
              @keypress="isNumber($event)"
            >
          </div>
        </div>
        <div class="card-input-buttons btn-withdraw" :class="{ 'btn-withdraw-mb': isWithDrawalAble }">
          <button
            class="btn card-input-button btn-color"
            :class="{ 'd-none': !isWithDrawalAble }"
            :disabled="vestingPool.account.claimable <= 0"
            @click="withdraw"
          >
            Withdraw
          </button>
        </div>
      </div>
    </template>
    <template v-if="$store.state.isLogin" #footer>
      <div class="footer-top ml-3">
        Next Claim Time： {{ nextClaimDate }} <br>
        Suppose a quantity： {{ vestingPool.account.claimNextAmount > 0 ? toFormattedToken(vestingPool.account.claimNextAmount): 0 + ' ' + account.symbol }} <br>
      </div>
    </template>

    <withdraw-modal
      :ref="modalWithdrawalId"
      :modal-data="{
        modalWithdrawalId,
        withdrawTitle,
      }"
      :withdrawal-tokens="withdrawalTokens"
      :loading-class="classes"
      @onHideModal="hideModal"
      @onSetClasses="setClasses"
      @resetWithdrawalTokens="resetWithdrawalTokens"
    />
  </card>
</template>

<script>
import WithdrawMixin from '@/mixins/modal';
import BaseMixin from '@/mixins/base';
import Web3 from 'web3';
import moment from 'moment';
import numFormat from 'number-format.js';
import { WALLET_CONNECT, METAMASK } from '@/utils/constants';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { getAccountData, getPools } from '@/utils/vesting';
import network from '@/domain/vesting/network';
import token from '@/domain/vesting/token';

export default {
  name: 'VestingCard',

  components: {
    WithdrawModal: () => import('./modal/WithdrawModal.vue'),
  },

  mixins: [WithdrawMixin, BaseMixin],

  data() {
    return {
      withdrawalTokens: '',
      poolsDataInterval: null,
    };
  },

  computed: {
    tokensAmount() {
      return this.withdrawalTokens.replaceAll(',', '');
    },

    nextClaimDate() {
      return this.vestingPool.account.claimNextTime != 0
        ? moment(parseInt(this.vestingPool.account.claimNextTime) * 1000).format('YYYY/MM/DD HH:mm')
        : '--';
    },

    isWithDrawalAble() {
      return (
        Number(this.tokensAmount) === 0
      );
    },

    vestingPool() {
      return this.$store.state.vesting.pools ? this.$store.state.vesting.pools[0] : [];
    },
  },

  watch: {
    withdrawalTokens(newValue, oldValue) {
      this.withdrawalTokens = this.toFormatNumber(newValue.replaceAll(',', ''));
    },
  },

  async mounted() {
    if (!this.$store.state.isLogin) {
      const provider = new WalletConnectProvider({
        chainId: network.chainIds[0],
        rpc: {
          [network.chainIds[0]]: network.rpcUrls[0],
        },
      });

      if (provider.connected) {
        await provider.disconnect();
        localStorage.removeItem('walletconnect');
      }

      const instance = new Web3(new Web3.providers.HttpProvider(network.rpcUrls[0]));
      const chainId = await instance.eth.net.getId();

      this.$store.dispatch('web3/update', {
        instance,
        chainId,
        provider: null,
      });
    }

    if (this.$store.state.isLogin) {
      // WALLETCONNECT
      if (sessionStorage.getItem('provider') == WALLET_CONNECT) {
        const provider = new WalletConnectProvider({
          chainId: network.chainIds[0],
          rpc: {
            [network.chainIds[0]]: network.rpcUrls[0],
          },
        });

        if (provider.connected) {
          await provider.disconnect();
        }

        await provider.enable();

        const instance = new Web3(provider);
        const chainId = await instance.eth.net.getId();

        this.$store.dispatch('web3/update', {
          instance,
          chainId,
          provider: WALLET_CONNECT,
        });
      }

      // METAMASK
      if (sessionStorage.getItem('provider') == METAMASK) {
        if (!window.ethereum) {
          return;
        }

        const instance = new Web3(window.ethereum);
        const chainId = await instance.eth.net.getId();

        if (chainId !== network.chainIds[0]) {
          this.$root.$emit('global-notification', {
            title: 'Connect Failed',
            type: 'error',
            content: 'Please check the network!',
          });

          await this.onLogout();

          return;
        }

        this.$store.dispatch('web3/update', {
          instance,
          chainId,
          provider: METAMASK,
        });
      }

      // Subscribe to accounts change
      this.web3.instance.currentProvider.on('accountsChanged', (accounts) => {
        this.handleAccountChanged(accounts);
      });

      // Subscribe to chainId change
      this.web3.instance.currentProvider.on('chainChanged', (chainId) => {
        this.handleChainChanged(chainId);
      });

      // Subscribe to session disconnection
      this.web3.instance.currentProvider.on('disconnect', () => {
        this.handleDisconnected();
      });

      const accountData = await getAccountData(this.web3.instance, token);

      if (accountData) {
        this.$store.dispatch('account/updateAccountData', accountData);

        getPools(this.web3.instance, this.account.address)
          .then((result) => {
            this.$store.dispatch('vesting/updatePools', result);
          })
          .catch((e) => console.error(e));

        this.$root.$emit('global-notification', {
          title: 'Account Connected',
          type: 'success',
          content: 'Your account is connected!',
        });
      }

      this.getPoolsDataInterval(this.web3.instance, 5);
    }
  },

  methods: {
    withdraw() {
      this.$store.dispatch('withdraw/updateVestingPool', this.vestingPool);
      this.showModal(this.modalWithdrawalId);
    },

    maxToken(methods) {
      if (methods === 'withdrawal') {
        let subAmount = this.web3.instance.utils.fromWei(this.vestingPool.account.claimable).toString().split('.', 2);

        this.withdrawalTokens = subAmount.length > 1 ? subAmount[0] + '.' + subAmount[1].slice(0, 2) : subAmount[0];
      }
    },

    getPoolsDataInterval(instance, seconds) {
      this.poolsDataInterval = setInterval(() => {
        getPools(instance, this.account.address)
          .then((result) => {
            this.$store.dispatch('vesting/updatePools', result);
            this.$store.dispatch('account/updateBalance', result[0].balanceOfUser);
          })
          .catch((e) => console.error(e));
      }, seconds * 1000);
    },

    resetWithdrawalTokens(amount) {
      this.withdrawalTokens = amount;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-withdraw {
  display: flex;
  align-items: flex-end;
}
.btn-withdraw-mb {
  margin-top: 20px;
}
</style>
