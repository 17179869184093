export default {
  data() {
    return {
      modalWithdrawalId: 'withdraw-' + (Math.random() + 1).toString(36).substring(7),
      withdrawTitle: 'Withdrawal',
      classes: [],
    };
  },

  methods: {
    showModal(modalId) {
      this.$refs[modalId].$children[0].show();
    },
    hideModal(modalId) {
      this.$refs[modalId].$children[0].hide();
    },
    setClasses(isLoading) {
      isLoading
        ? this.classes.push('no-hover', 'is-loading')
        : (this.classes = []);
    },
  },
};
