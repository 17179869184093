<template>
  <div class="page-home">
    <h1 class="text-title-2 text-center font-weight-bold">
      <span class="mr-4">NLC Vesting</span>
      <span>🎉</span>
    </h1>
    <p class="card-description">
      World's First! Bringing WEB3.0 to Cambodia<br>
      An Innovative Investment Experience with NLC<br>
    </p>
    <div class="box-cards">
      <vesting-card />
      <div class="footer-text">
        <span>How to withdraw NLC? </span>
        <span>1. Connect NLC vesting and Metamask.</span>
        <span id="margin-bot-mb"> - If you are using a mobile device, please access using Metamask app browser.</span>
        <span>2. The reward is NLC, the first Bringing WEB3.0 to Cambodia
          <!--<br> Operation period is 30days from 10/20 to 11/20.-->
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-text {
  width: 100%;
  max-width: 715px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  letter-spacing: .6px;
  line-height: 36px;
  margin-top: 2rem;
  font-weight: 500;

  @media (max-width: 414px) {
    line-height: 22px;
    font-size: 14px;

    #margin-bot-mb {
      margin-bottom: 25px;
    }
  }

  @media (min-width: 995px) {
    margin-left: auto;
    margin-right: auto;
  }
}

</style>

<script>
import VestingCard from '@/components/vesting/VestingCard.vue';

export default {
  name: 'NlcVesting',
  components: {
    VestingCard,
  },
};
</script>
